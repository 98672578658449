
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Projets",
})
export default class Projets extends Vue {
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Projets");
  }
}
